export const environment = {
    production: false,
    //api: 'http://localhost:8080/api',
    //restapi: 'http://localhost:8080/api/restapi/',
    api: 'https://innovation-prod.cmq.io/api',
    restapi: 'https://innovation-prod.cmq.io/api/restapi/',
    mockedClinicId: 41,
    amazon: {
        bucketName: 'devportailweb',
        url: 'https://s3.amazonaws.com/devportailweb/',
        region: 'us-east-1'
    },
    oab: {
        medical: 'https://devbook1.chronometriq.com/#',
        paramedical: 'https://devbook2.chronometriq.com/#'
    },
    twilio: {
        from: '+14387956945'
    }
};
